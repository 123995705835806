import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Detail from "./Detail";


class NotFound extends Component {
    render() {
        return (
            <div className="App-header">
                <div>
                    <h2>Not Found</h2>
                </div>
            </div>
        );
    }
}

export default NotFound;
